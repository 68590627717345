<template>
  <div class="ss-select-org">
    <span v-if="showLabel">组织机构：</span>

    <div class="input select ant-select"
         :class="{'ant-select-disabled': disabled}">
      <div class="input ant-select-selection ant-select-selection--multiple"
           @click="showModal">
        <div class="ant-select-selection__rendered">
          <span v-if="!selectedData || !selectedData.length" class="ant-select-selection__placeholder">请选择组织机构</span>
          <span v-for="item of selectedData" :key="item.id" class="ant-select-selection__choice">
            {{ item.name }}
            <span class="delete-item-btn" @click.stop="deleteOrg(item)">X</span>
          </span>
        </div>
      </div>

      <div class="clear-btn" @click="clear">
        <img src="@/assets/img/close.png" alt="" class="close-img">
      </div>
    </div>

    <SelectOrgModal v-if="modalShown" :default-selected-ids="selectedIds"
                    :mode="mode" :only-leaf="onlyLeaf"
                    @confirm="select" @close="closeModal"></SelectOrgModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SelectOrgModal from '@components/SelectOrgModal';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrg',
  components: {SelectOrgModal},
  model: {
    prop: 'defaultSelectedIds',
    event: 'change',
  },
  props: {
    showLabel: { type: Boolean, default: true },
    defaultSelectedIds: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: { type: String, default: 'multiple', validator: (val) => ['single', 'multiple'].includes(val) },
    onlyLeaf: { type: Boolean, default: false }, // 只允许选择没有子节点的数据
  },
  computed: {
    ...mapState({
      options: state => state.Common.orgTree,
    }),
  },
  watch: {
    defaultSelectedIds() {
      this.setDefault();
    },
    options() {
      this.setDefault();
    },
  },
  data() {
    return {
      modalShown: false,
      selectedData: [],
      selectedIds: '',
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.selectedIds = this.defaultSelectedIds;
      const keys = (this.selectedIds.split(',') || []).map(i => +i);
      this.selectedData = Common.getSelectedItems(keys, this.options, { id: 'id', name: 'name' });
    },
    showModal() {
      if (this.disabled) {
        return;
      }
      this.modalShown = true;
    },
    closeModal() {
      this.modalShown = false;
    },

    deleteOrg(item) {
      const data = this.selectedData.filter(i => i.id !== item.id);
      this.select({data});
    },

    clear() {
      this.selectedData = [];
      this.selectedIds = '';
      this.select({data: []});
    },

    select(event) {
      this.selectedData = event?.data || [];
      this.selectedIds = this.selectedData.map(i => i.id).join(',');
      this.closeModal();
      this.$emit('select', event);
      this.$emit('change', this.selectedIds);
    },

  },
};
</script>

<style scoped lang="scss">
.ss-select-org {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(5px);

  .input {
    position: relative;
    flex: auto;
    overflow-y: auto;
    min-width: 350px;
    max-height: 120px;
  }

  .ant-select-disabled .ant-select-selection:hover {
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-select-disabled .ant-select-selection {
    cursor: not-allowed;
  }

  .ant-select-selection {
    padding-right: 30px;
    cursor: pointer;
  }

  .ant-select-selection__choice {
    margin-top: 3px;
    padding-right: 30px;
  }

  .delete-item-btn, .clear-btn {
    $size: 12px;

    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: $size;
    height: $size;
    border-radius: 50%;
  }

  .select:hover .clear-btn, .delete-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clear-btn {
    display: none;
    font-size: 12px;
    background-color: #C1C1C1;

    &:hover {
      cursor: pointer;
      background-color: #919191;
    }

    .close-img {
      $size: 7px;
      width: $size;
      height: $size;
    }
  }

  .delete-item-btn {
    right: 4px;
    font-size: 12px;
    zoom: 0.9;
    color: #929292;

    &:hover {
      cursor: pointer;
      color: #3F3F3F;
    }
  }
}
</style>
