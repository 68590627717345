<template>
  <div v-if="shown" class="layer" @click="close">
    <div v-if="urls && urls.length" class="multiple-viewer viewer">
      <a-carousel ref="carouselRef" :dots="false">
        <div v-for="(item, idx) in urls" :key="idx" class="image-box">
          <img :src="item" alt="" class="img"/>
        </div>
      </a-carousel>

      <div class="viewer-dots">
        <div v-for="(item, idx) in urls" :key="idx" class="viewer-dot"
             :class="{'active': dotIndex === idx}"
             @click.stop="toView(idx)"></div>
      </div>

      <span class="text">点击任意处关闭</span>
    </div>

    <div v-else class="single-viewer viewer">
      <div class="image-box">
        <img :src="url" alt="" class="img"/>
      </div>
      <span class="text">点击任意处关闭</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
    url: { type: String, default: '' },
    urls: { type: Array, default: () => [] },
  },
  data() {
    return {
      shown: false,

      dotIndex: 0,
    };
  },
  methods: {
    show() {
      this.shown = true;
    },
    close() {
      this.shown = false;
      this.$emit('close');
    },

    toView(index) {
      this.dotIndex = index || 0;
      this.$refs.carouselRef.goTo(this.do);
    },
  },
};
</script>

<style scoped lang="scss">
.layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.2);
}

.multiple-viewer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 60vh;
  margin: 0 auto;

  .image-box {
    width: 80vw;
    height: calc(60vh - 20px);
    position: relative;

    .img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    position: absolute;
    left: 50%;
    bottom: -100px;
    transform: translateX(-50%);
  }
}

.single-viewer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image-box {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 80%;
    text-align: center;
  }

  .img {
    flex: none;
    max-width: 100%;
    max-height: 100%;
  }
}

.viewer .text {
  margin-top: 6px;
  padding: 6px 12px;
  border-radius: 2px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.35);
}

.viewer-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .viewer-dot {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 25px;
    background-color: #DADADA;
    cursor: pointer;

    &.active {
      background-color: #FEFEFE;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
