<template>
  <div class="app-base-label-action" :class="[customClass]">
    <div v-if="showLabel" class="app-base-label-action-label"
         :style="{'width': fixedLabelWidth ? (fixedLabelWidth + 'px') : 'auto',
         'flex': fixedLabelWidth ? 'none' : 'auto'}">
      <slot name="label"></slot>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseLabelAction',
  props: {
    customClass: {type: String, default: ''},
    showLabel: {type: Boolean, default: true},
    fixedLabelWidth: {type: Number, default: 0},
  },
}
</script>

<style scoped lang="scss">
.app-base-label-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .app-base-label-action-label {
    white-space: nowrap;
    margin-right: 4px;
    text-align: right;
  }
}
</style>
